<template>
  <OtherBanner :type="16" />

  <section class="knowledge">
    <div class="knowledge-wrap">
      <div class="sidebar">
        <div class="Kngh1">
          <div>
            <!-- 知识库目录 -->
            {{ $t("knowledge.knowledge_catalog") }}（<em class="blue">{{
              pageTotal
            }}</em
            >）
          </div>
        </div>
        <div class="tree-menu" v-if="folderData.length">
          <a-skeleton :loading="!folderData.length" active>
            <a-directory-tree
              v-model:selectedKeys="selectedKeys"
              :replace-fields="replaceFields"
              :showIcon="false"
              :tree-data="folderData"
              @select="treeSelect"
              v-model:expandedKeys="expandedKeys"
            >
            </a-directory-tree>
          </a-skeleton>
        </div>
        <div class="tree-menu" v-else>
          <p class="no-menu">{{ $t("knowledge.no_catalog") }}</p>
          <!-- 暂无目录 -->
        </div>
        <div class="Kngh1">
          <div>{{ $t("knowledge.knowledge_base_of") }}</div>
          <!-- 知识库情况 -->
        </div>
        <div class="KngqkBg">
          <div class="totals">
            {{ $t("knowledge.total_knowledge", 1) }} <em>{{ Totals }}</em>
            {{ $t("knowledge.total_knowledge", 2) }}
            <!-- 共有 知识 -->
          </div>
          <div class="types">
            <div
              class="item fl"
              v-for="(item, index) in fileTypeOptions"
              :key="index"
            >
              <img :src="require(`../../assets/image/knowledgeType/${item.value}.png`)" />
              <span>{{ item.label }}</span>
              <span class="num">{{ item.total }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="Kngh1">
          <div>{{ $t("knowledge.knowledge_content") }}</div>
          <!-- 知识库内容 -->
          <div class="search">
            <div class="search-icon" @click="search">
              <SearchOutlined style="color: #656565; font-size: 14px" />
            </div>
            <a-input
              class="search-input"
              v-model:value.trim="searchVal"
              :placeholder="$t('please_enter_keywords')"
              allow-clear
              @pressEnter="search"
            />
            <!-- 请输入关键字 -->
          </div>
        </div>
        <div class="KngContBg">
          <div class="KngContH">
            <div class="KngContHL">
              <a :class="{ on: orderActive === 1 }" @click="orderChange(1)">{{
                $t("CM_TimeIsNew")
              }}</a>
              <!-- 时间最新 -->
              <a :class="{ on: orderActive === 2 }" @click="orderChange(2)">{{
                $t("CM_PopularityRanking")
              }}</a>
              <!-- 人气排序 -->
            </div>
            <div class="KngContHR">
              <a-checkbox-group
                v-model:value="typesActive"
                name="checkboxgroup"
                :options="fileTypeOptions"
                @change="typeChange"
              />
              <div class="HR-btn">
                <a :class="{ on: listType === 0 }" @click="listType = 0"
                  ><i class="icon-tu"></i>{{ $t("knowledge.big_pic") }}</a
                >
                <!-- 大图 -->
                <a :class="{ on: listType === 1 }" @click="listType = 1"
                  ><i class="icon-list"></i>{{ $t("knowledge.list") }}</a
                >
                <!-- 列表 -->
              </div>
            </div>
          </div>
          <a-spin :spinning="Loading">
            <template v-if="dataList.length">
              <div class="cards clearfix" v-if="listType === 0">
                <div
                  class="item fl"
                  v-for="item in dataList"
                  :key="item.knowledgeId"
                >
                  <div class="data" @click="clickJump(item)">
                    <div class="main">
                      <img
                        class="cover"
                        :src="
                          item.coverImg ||
                          require('../../assets/image/knowledge_cover.png')
                        "
                      />
                      <div class="detail">
                        <h1>
                          <img
                            :src="
                              require(`../../assets/image/knowledgeType/${item.fileType}.png`)
                            "
                          />
                          <a-tooltip>
                            <template #title><span v-html="item.fileName"></span></template>
                            <span v-html="item.fileName"></span>
                          </a-tooltip>
                        </h1>
                        <a-tooltip v-if="item.intro">
                          <template #title><span v-html="item.intro"></span></template>
                          <p v-html="item.intro"></p>
                        </a-tooltip>
                        <p v-else></p>
                        <div class="row">
                          <div class="user-name">
                            <span>{{ $t("knowledge.sharer") }}：</span>
                            <!-- 分享人员： -->
                            <OpenData
                              type="userName"
                              :openid="item.createUser"
                            />
                          </div>
                          <div>
                            <!-- 上传时间： -->
                            <span>{{ $t("LB_Doc_UploadTime") }}：</span
                            >{{
                              moment(item.createTime * 1000).format(
                                "YYYY-MM-DD"
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notes">
                      <div class="notes-item">
                        <EyeOutlined style="font-size: 16px" />{{
                          item.learnCount
                        }}
                      </div>
                      <div class="notes-item">
                        <LikeOutlined style="font-size: 16px" />{{ item.likes }}
                      </div>
                      <div class="notes-item">
                        <DownloadOutlined style="font-size: 16px" />{{
                          item.downs
                        }}
                      </div>
                      <div class="notes-item">
                        <MessageOutlined style="font-size: 16px" />{{
                          item.comments
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ListTable clearfix" v-else>
                <a-table
                  :scroll="{ x: 884 }"
                  :columns="columns"
                  rowKey="knowledgeId"
                  :data-source="dataList"
                  :pagination="false"
                  size="middle"
                >
                  <template #name="{ text, record }">
                    <a-tooltip>
                      <template #title><span v-html="text"></span></template>
                      <span class="pointer" @click="clickJump(record)" v-html="text"></span>
                    </a-tooltip>
                  </template>
                  <template #fileType="{ record }">
                    <span>{{ getFileType(record.fileType) }}</span>
                  </template>
                  <template #createTime="{ record }">
                    <span>{{
                      moment(record.createTime * 1000).format("YYYY-MM-DD")
                    }}</span>
                  </template>
                  <template #createUser="{ text }">
                    <OpenData type="userName" :openid="text" />
                  </template>
                  <template #action="{ record }">
                    <a @click="clickJump(record)">{{ $t("cm_view") }}</a>
                    <!-- 查看 -->
                  </template>
                </a-table>
              </div>
            </template>
            <a-empty v-else style="padding: 272px 0" />
          </a-spin>
        </div>
        <div class="page-Wrap" v-if="dataList.length">
          <a-pagination
            show-quick-jumper
            :defaultPageSize="10"
            v-model:current="currentPage"
            :show-total="(total) => $t('XB_InTotal', [pageTotal])"
            :total="pageTotal"
            @change="pageChange"
          >
            <template #itemRender="{ type, originalElement }">
              <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
              <a class="page-a" v-else-if="type === 'next'">{{
                $t("cm_next")
              }}</a>
              <renderVNode v-else :vnode="originalElement"></renderVNode>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useStore } from "vuex";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import { fileType } from "@/utils/business";
import { knowledgelist, knowledgefolder } from "@/api/knowledge";
import { getFileType } from "@/utils/business";
import moment from "moment";
import OpenData from "@/components/OpenData.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
    OpenData,
    OtherBanner,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    // 目录
    let replaceFields = ref({
      children: "list",
      title: "name",
      key: "id",
      value: "id",
    });
    let folderId = 0;
    let listType = ref(0);
    const columns = [
      {
        title: $t("knowledge.table.file_name"),
        // 知识名称
        dataIndex: "fileName",
        width: 300,
        ellipsis: true,
        slots: { customRender: "name" },
      },
      {
        title: $t("knowledge.table.file_type"),
        // 文件类型
        dataIndex: "fileType",
        align: "center",
        slots: { customRender: "fileType" },
        width: 100,
      },
      {
        title: $t("knowledge.table.downloads"),
        // 下载量
        dataIndex: "downs",
        align: "center",
        width: 100,
      },
      {
        title: $t("knowledge.table.likes"),
        // 获赞量
        dataIndex: "likes",
        align: "center",
        width: 100,
      },
      {
        title: $t("knowledge.table.views"),
        // 浏览量
        dataIndex: "learnCount",
        align: "center",
        width: 100,
      },
      {
        title: $t("knowledge.table.comments"),
        // 评论数
        dataIndex: "comments",
        align: "center",
        width: 100,
      },
      {
        title: $t("knowledge.table.sharer"),
        // 分享人
        dataIndex: "createUser",
        align: "center",
        slots: { customRender: "createUser" },
        width: 100,
      },
      {
        title: $t("knowledge.table.upload_time"),
        // 上传时间
        dataIndex: "createTime",
        align: "center",
        slots: { customRender: "createTime" },
        width: 100,
      },
      {
        title: $t("cm_operate"),
        // 操作
        dataIndex: "action",
        align: "center",
        slots: { customRender: "action" },
        width: 100,
      },
    ];
    let selectedKeys = ref([0]);
    let folderData = ref([]);
    let expandedKeys = [];
    knowledgefolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
        for(let i=0; i<folderData.value.length; i++){
          expandedKeys.push(folderData.value[i].id);
        }
        folderData.value.splice(0, 0, {
          id: 0,
          list: null,
          name: $t("CM_All"),
          // name: "全部",
        });
      }
    });

    const treeSelect = (e, node) => {
      folderId = e[0];
      getList();
    };

    // 格式
    const fileTypeOptions = [
      { label: $t("Pub_Video"), value: 1, total: 0 }, // 视频
      { label: $t("LB_AudioFrequency"), value: 2, total: 0 }, // 音频
      { label: $t("Pub_Document"), value: 3, total: 0 }, // 文档
      { label: $t("knowledge.pic"), value: 4, total: 0 }, // 图片
      { label: "H5", value: 6, total: 0 },
      { label: $t("XB_DocumentText"), value: 9, total: 0 },//文本
    ];
    let typesActive = [0];
    const typeChange = (arr) => {
      typesActive = arr;
      getList();
    };

    // 排序
    const orderActive = ref(1),
      orderChange = (type) => {
        orderActive.value = type;
        getList();
      };

    // 热门搜索
    const hotData = ref([
        "飞训科技软件产品开发规范",
        "成功领导者的六种思维方法",
        "管理平台操作手册",
        "平台介绍",
        "公司经营原则k",
      ]),
      hotChange = (item) => {
        console.log(item);
      };

    // 跳转详情
    const clickJump = (data) => {
      data.learnCount++;
      let urlStr = "/doc/detail?knowledgeId=" + data.knowledgeId;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getList();
      };

    // 知识库
    const dataList = ref([]),
      Totals = ref(0),
      Loading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(0),
      getList = (scroll = false) => {
        Loading.value = true;
        knowledgelist({
          page: currentPage.value,
          pageSize: 10,
          name: searchVal.value,
          folderId: folderId,
          order: orderActive.value,
          types: typesActive[0] === 0 ? [] : typesActive,
        }).then((res) => {
          Loading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            dataList.value = res.data.list || [];
            //获取每种类型的总数
            siftTypeTotals(res.data.types || {});
          }
        });
      },
      siftTypeTotals = (types) => {
        Totals.value = 0;
        fileTypeOptions.map((item) => {
          item.total = 0;
        });
        if (types[1]) {
          fileTypeOptions[0].total = types[1];
          Totals.value += types[1];
        }
        if (types[2]) {
          fileTypeOptions[1].total = types[2];
          Totals.value += types[2];
        }
        if (types[3]) {
          fileTypeOptions[2].total = types[3];
          Totals.value += types[3];
        }
        if (types[4]) {
          fileTypeOptions[3].total = types[4];
          Totals.value += types[4];
        }
        if (types[6]) {
          fileTypeOptions[4].total = types[6];
          Totals.value += types[6];
        }
        if (types[9]) {
          fileTypeOptions[5].total = types[9];
          Totals.value += types[9];
        }
      },
      pageChange = (n) => {
        currentPage.value = n;
        getList(true);
      };
    getList();

    return {
      replaceFields,
      selectedKeys,
      folderData,
      clickJump,
      treeSelect,
      listType,
      columns,
      fileType,
      fileTypeOptions,
      typesActive,
      typeChange,
      orderActive,
      orderChange,
      hotData,
      hotChange,
      searchVal,
      search,
      getList,
      dataList,
      Loading,
      currentPage,
      pageTotal,
      pageChange,
      moment,
      getFileType,
      Totals,
      expandedKeys,
    };
  },
};
</script>

<style lang="less" scoped>
::v-deep(.banner) {
  .img {
    border-radius: 8px;
  }
}
.knowledge {
  color: #444;
  background-color: @color-page-gray;
  .knowledge-wrap {
    .mixinWrap();
    .mixinFlex(space-between);
    padding: 22px 0 42px;
    .Kngh1 {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      line-height: 50px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .blue {
        color: @color-theme;
      }
      .search {
        border: 1px solid #ccc;
        .mixinFlex(space-between; center);
        width: 220px;
        height: 32px;
        background-color: #fff;
        border-radius: 16px;
        overflow: hidden;
        float: right;
        &-icon {
          width: 34px;
          height: 14px;
          .mixinFlex(center; center);
          border-right: 1px solid #bbbbbb;
          cursor: pointer;
        }
        ::v-deep(.ant-input-affix-wrapper-focused) {
          border-color: none;
          box-shadow: none;
        }
        &-input {
          border: none;
          width: calc(100% - 34px);
          height: 100%;
          background-color: #fff;
          padding: 0 8px;
          font-size: 14px;
          ::v-deep(.ant-input) {
            background-color: #fff;
          }
        }
      }
    }
    .sidebar {
      width: 300px;
      .tree-menu {
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
        background-color: #fff;
        max-height: 752px;
        overflow: auto;
        .no-menu {
          margin: 10px;
          text-align: center;
          color: #999;
        }
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: #e8eaed;
        }
        ::v-deep(.ant-tree li span.ant-tree-switcher, .ant-tree
            li
            span.ant-tree-iconEle) {
          width: 34px;
          height: 34px;
          line-height: 34px;
        }
        ::v-deep(.ant-tree li .ant-tree-node-content-wrapper) {
          height: 34px;
          line-height: 34px;
          padding: 0 10px;
        }
        ::v-deep(.ant-tree.ant-tree-directory
            > li
            span.ant-tree-node-content-wrapper::before, .ant-tree.ant-tree-directory
            .ant-tree-child-tree
            > li
            span.ant-tree-node-content-wrapper::before) {
          height: 34px;
          border-radius: 4px;
        }
        ::v-deep(.ant-tree.ant-tree-directory
            > li.ant-tree-treenode-selected
            > span.ant-tree-node-content-wrapper::before) {
          background: @color-theme;
        }
        ::v-deep(.ant-tree.ant-tree-directory
            .ant-tree-child-tree
            > li.ant-tree-treenode-selected
            > span.ant-tree-node-content-wrapper::before) {
          background: @color-theme;
        }
      }
      .KngqkBg {
        padding: 0 0 25px 30px;
        border-radius: 8px;
        box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
        background-color: #fff;
        overflow: auto;
        .totals {
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 30px;
          padding: 18px 0;
          em {
            color: #ff7700;
            font-weight: bold;
            padding: 0 6px;
            font-size: 26px;
          }
        }
        .types {
          .item {
            height: 32px;
            font-size: 16px;
            color: #666;
            width: 134px;
            display: inline-block;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            img {
              border-radius: 4px;
              margin: 0 8px 0 0;
              width: 32px;
              height: 32px;
            }
            span {
              display: inline-block;
              width: 45px;
              vertical-align: -1px;
              &.num {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 18px;
                vertical-align: -2px;
              }
            }
          }
        }
      }
    }
    .content {
      width: 878px;
      .KngContBg {
        background-color: #f7f7f7;
        padding: 0 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
        overflow: hidden;
        border: 1px solid #ededed;
        .KngContH {
          display: flex;
          justify-content: space-between;
          padding: 16px 0;
          height: 62px;
          background-color: #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          margin-bottom: 16px;
          .KngContHL {
            a {
              line-height: 29px;
              height: 31px;
              box-sizing: border-box;
              padding: 0 16px;
              border-radius: 4px;
              margin-right: 16px;
              display: inline-block;
              &.on {
                background-color: #fff;
                border: 1px solid #377dff;
                color: #377dff;
              }
            }
          }
          .KngContHR {
            ::v-deep(.ant-checkbox-group-item) {
              font-size: 12px;
              vertical-align: -3px;
            }
            .HR-btn {
              display: inline-block;
              height: 20px;
              border: 1px solid #bfbfbf;
              font-size: 12px;
              border-radius: 2px;
              overflow: hidden;
              box-sizing: border-box;
              vertical-align: -8px;
              margin-left: 8px;
              .icon-tu {
                background: url(~@/assets/image/kng-ico.png) -24px 0;
              }
              .icon-list {
                background: url(~@/assets/image/kng-ico.png) -36px 0;
              }
              a {
                display: inline-block;
                height: 20px;
                line-height: 18px;
                padding: 0 4px;
                vertical-align: top;
                color: #999;
                i {
                  display: inline-block;
                  width: 12px;
                  height: 12px;
                  margin-right: 4px;
                  vertical-align: -1px;
                }
                &:not(:last-child) {
                  border-right: 1px solid #bfbfbf;
                }
                &.on {
                  background: #fff;
                  color: #333;
                  .icon-tu {
                    background: url(~@/assets/image/kng-ico.png) 0 0;
                  }
                  .icon-list {
                    background: url(~@/assets/image/kng-ico.png) -12px 0;
                  }
                }
              }
            }
          }
        }
      }
      .cards {
        .item {
          margin-right: 10px;
          margin-bottom: 16px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .data {
            width: 412px;
            height: 152px;
            border-radius: 8px;
            background-color: #fff;
            cursor: pointer;
            &:hover {
              box-shadow: 0px 0px 10px #ccc;
            }
            .main {
              .mixinFlex(space-between; center);
              padding: 10px 14px;
              .cover {
                width: 68px;
                height: 90px;
                border-radius: 6px;
              }
              .detail {
                width: calc(100% - 80px);
                h1 {
                  .mixinFlex(flex-start; center);
                  font-size: 16px;
                  margin-bottom: 4px;
                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                  }
                  span {
                    .mixinEllipsis(20px);
                    font-weight: 600;
                    span > em {
                      color: red;
                    }
                  }
                }
                p {
                  font-size: 12px;
                  .mixinEllipsis(42px; 2);
                  margin-bottom: 4px;
                  span {
                    color: #999;
                  }
                }
                .row {
                  font-size: 12px;
                  .mixinFlex(space-between; center);
                  line-height: 20px;
                  .user-name {
                    .mixinEllipsis(20px);
                    max-width: 130px;
                  }
                  span {
                    color: #999;
                  }
                }
              }
            }
            .notes {
              border-top: 1px solid #f4f4f4;
              height: 41px;
              .mixinFlex(space-between; center);
              &-item {
                width: 25%;
                .mixinFlex(center; center);
                color: #bbb;
                span {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      .ListTable {
        ::v-deep(.ant-table table) {
          font-size: 12px;
          margin-bottom: 16px;
          .ant-table-row:hover {
            box-shadow: 0px 0px 10px #ccc;
            border-radius: 4px;
            td {
              background: none;
            }
          }
          a {
            color: @color-theme;
          }
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
  &:hover {
    color: var(--theme);
  }
}
</style>
